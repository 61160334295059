import React from 'react';

import loadable from '@loadable/component';

import { Protected } from '@components/auth';
import { IsNotDesktop } from '@components/layout/desktop/IsNotDesktop';
import { Layout } from '@components/layout/desktop/Layout';

const Dashboard = loadable(() => import('@desktop/dashboard/Dashboard'));

function Index(props) {
  return (
    <Protected>
      <IsNotDesktop>
        <Layout {...props}>
          <Dashboard {...props} />
        </Layout>
      </IsNotDesktop>
    </Protected>
  );
}

export default Index;
